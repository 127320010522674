<template>
  <div class="app-wrapper">
    <div id="content-desktop">
      <router-view />
    </div>

    <div id="content-mobile">
      <div class="overlay"></div>
      <p>Not available via mobile. Please visit on Desktop</p>
    </div>
    <footer>
      <router-link to="/imprint">Imprint</router-link>
      <router-link to="/privacypolicy">Privacy Policy</router-link>
    </footer>
  </div>
</template>
<style>
h1 {
  margin: 24px;
}
</style>
<script>


export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style scoped>
.app-wrapper,
.app-wrapper > :not(footer) {
  height: 100%;
}

#content-mobile {
  display: none;
}

@media screen and (max-width: 1025px) {
  #content-desktop {
    filter: blur(5px);
    opacity: 0.45;
  }
  #content-mobile {
    position: fixed;
    display: block;
    top: 0;
    margin: 4rem;
    height: 100vh;
    width: calc(100vw - 8rem);
  }
}
</style>
