import { createWebHistory, createRouter } from "vue-router";

const routes = [
    {
        path: "/",
        name: "Home",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/Home.vue')
    },
    {
        path: "/imprint",
        name: "Imprint",
        component: () => import(/* webpackChunkName: "Imprint" */ '@/views/Imprint.vue')
    }, {
        path: "/privacypolicy",
        name: "PrivacyPolicy",
        component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '@/views/PrivacyPolicy.vue')
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
